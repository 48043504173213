import {ReactElement, ReactNode, useEffect, useState} from "react";
import {useApi} from "@/hoc/Api/context";
import {useLocation, useNavigate} from "react-router-dom";
import Loader from "@/components/Loader";

interface LoginProviderProps {
    children: ReactNode;
}

const LoginProvider = (props: LoginProviderProps): ReactElement => {
    const api = useApi()
    const navigate = useNavigate()
    const [proceed, setProceed] = useState<boolean>(false);
    let location = useLocation();

    useEffect(()=>{
        api.IsLogged().then(resp=>{
            if(!resp){
                navigate(`/Auth?from=${location.pathname}`)
            } else {
                setProceed(true)
            }
        })
    },[])

    if (!proceed) {
        return <Loader />
    }

    return <>
        {props.children}
    </>
}

export default LoginProvider
