import React, {ReactElement, useEffect, useRef, useState} from "react";
import Layout from "@/components/Layout";
import Preorder, {PreorderState} from "@/models/Preorder";
import {useApi} from "@/hoc/Api/context";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "@/components/Loader";
import {useSeo} from "@/hoc/Seo/context";
import {SeoPage} from "@/hoc/Seo/provider";
import MembershipCard from "@/models/MembershipCard";
import Accordion from "@/components/Accordion/Accordion";
import data from "@/static/json/frequentlyAskedQuestions.json";

const CheckoutCompleted = ({...props}): ReactElement => {
    const [order, setOrder] = useState<Preorder | undefined>(undefined)
    const [membershipCards, setMembershipCards] = useState<MembershipCard[]>([])
    let reloadInterval = useRef<NodeJS.Timer>()
    const {id} = useParams()
    const api = useApi()
    const seo = useSeo()
    const navigate = useNavigate()
    const cleanReloadInterval = () => {
        clearInterval(reloadInterval.current)
    }
    const pollingStates = [PreorderState.Transferring, PreorderState.Pending, PreorderState.TransferCompleted]
    useEffect(() => {
        if (id === undefined) {
            navigate("/Checkout")
            return
        }
        seo.setSeo(SeoPage.checkout)
        api.GetMembershipCards().then(m => setMembershipCards(m))
        api.GetOrder(id).then(o => {
            setOrder(o)
            if (pollingStates.includes(o.state)) {
                 reloadInterval.current = setInterval(() => {
                    api.GetOrder(id).then(o => {
                        if (!pollingStates.includes(o.state)) {
                            cleanReloadInterval()
                        }
                        setOrder(o)
                    })
                }, 500)
            }
        })
        return () => {
            clearInterval(reloadInterval.current)
        }
    }, [])

    if (order === undefined || (order.state === PreorderState.Pending &&
        (order.clientData?.statusPayment !== "succeeded" && order.clientData?.statusPayment !== "processing"))) {
        return <Loader/>
    }

    let content: ReactElement;

    const reorderedFAQ = data.accordionSections
        .filter((section) => section.pages.some((page) => page === "failedTransfer"))
        .map((section, index) => {
            return {
                ...section,
                title: `${index + 1}. ${section.title}`
            };
        });

    switch (order.state) {
        case PreorderState.TransferFailed:
            content = (
                <>
                    <div className={"row col-md-8 col-12 justify-content-start mx-md-auto"}>
                        <div className={"col-md-6 text-start"}>
                            <h1 className={"information__title text--primary-color tex-center"}>Trasferimento <strong>fallito
                                :(</strong></h1>
                        </div>
                        <div className={"col-12 px-3 mt-4 text--heading-color text-start mb-4"}>
                            Il trasferimento dei biglietti non è andato a buon fine,<br/> non ti è stato addebitato alcun
                            importo.
                        </div>
                        <div className={"col-12 px-3 justify-content-start mt-3"}>
                            <button className="btn btn--gradient col-auto" onClick={() => {
                                navigate("/Partite")
                            }}>
                                <span>continua gli acquisti</span>
                            </button>
                        </div>
                    </div>
                    <div className={"row mt-5 justify-content-start"}></div>
                    <div className={"mt-5 column text-center"}>
                        <h2 className={"mb-4"}>
                            Domande <span className="font-weight-bold">frequenti</span>
                        </h2>
                        <div className={"mt-2 md-5"}>
                            <Accordion accordionId={data.accordionId} sections={reorderedFAQ} />
                        </div>
                    </div>
                </>
            )
            break
        case PreorderState.Completed:
            content = (
                <div className={"col-auto justify-content-center mx-2"}>
                    <div className={"row justify-content-center"}>
                        <h1 className={"information__title text--primary-color text-center"}>Checkout <strong>completato!</strong>
                        </h1>
                    </div>
                    <div className={"row justify-content-center pb-3 text--heading-color d-flex"} >
                        Il tuo acquisto è andato a buon fine :)
                    </div>
                    <div className={"row justify-content-center py-2 "}>
                        <button className="btn btn--outlined col-auto" onClick={() => {
                            navigate("/Profilo/Vendi")
                        }}>
                            <span className={"btn__text-gradient"}>visualizza biglietti</span>
                        </button>
                    </div>
                    <div className={"row justify-content-center py-2 "}>
                        <button className="btn btn--gradient col-auto" onClick={() => {
                            navigate("/Partite")
                        }}>
                            <span>continua gli acquisti</span>
                        </button>
                    </div>
                </div>
            )
            break
        case PreorderState.TransferCompleted:
            content = (
                <>
                    <h1 className={"information__title text--primary-color text-center"}>Stiamo finalizzando
                        il tuo <strong>ordine...</strong></h1>
                </>
            )
            break
        case PreorderState.Transferring:
            content = (
                <>
                    <h1 className={"information__title text--primary-color text-center"}>Stiamo trasferendo
                        i <strong>biglietti...</strong></h1>
                </>
            )
            break
        case PreorderState.TransferPartial:
            content = (
                <>
                <div className={"col-auto justify-content-center mx-2"}>
                    <div className={"row justify-content-center"}>
                        <h1 className={"information__title text--primary-color text-center"}><strong>Errore</strong> nel
                            trasferimento</h1>
                    </div>
                    <div className={"row justify-content-center mx-auto"} style={{maxWidth: "580px"}}>
                        <label className="information__title-red label-3">
                            Ops, il tuo ordine non è stato processato correttamente.
                            I biglietti evidenziati non sono stati trasferiti:
                        </label>
                    </div>
                    {
                        order.ticketOrders.map((t, i) => {
                            return (
                                <div className={"row justify-content-center py-3 mx-auto"} style={{maxWidth: "580px"}}>
                                    <div className={"row justify-content-center"}>
                                        <div className={"col-auto text--heading-color"}>
                                            Tessera: {String(membershipCards.find(m => m.uuid === t.targetMembershipCardUuid)?.fullName).toUpperCase()}
                                        </div>
                                        {
                                            t.error ?
                                                <div className={"col-auto information__title-red "}>
                                                    Non Trasferito
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                    <div className={"row justify-content-center"}>
                                        <div className={"col"}>
                                            <div className={"row justify-content-center text--heading-color"}>
                                                Partita: {t.mainIssuer} vs {t.secondaryIssuer}
                                            </div>
                                            <div className={"row justify-content-center text--heading-color"}>
                                                Settore {t.ticketDetails.section}, Fila {t.ticketDetails.row},
                                                Posto {t.ticketDetails.seat}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="row justify-content-center pb-3 mx-auto font-weight-light text--heading-color"
                         style={{maxWidth: "580px"}}>
                        Puoi ritentare il trasferimento dei biglietti non processati, confermare l’ordine (ti verrà
                        addebitato solo l’importo dei biglietti trasferiti) oppure cancellare l’ordine (non ti verrà
                        addebitato alcun importo).
                    </div>
                    <div className={"row justify-content-center py-2 "}>
                        <div className={"row justify-content-center py-2 "}>
                            <button className="btn btn--outlined col-auto" onClick={() => {
                                api.ConfirmPartialOrder(id ?? "").then(o => {
                                    setOrder(o)
                                    reloadInterval.current = setInterval(() => {
                                        api.GetOrder(order.uuid).then(o => {
                                            if (!pollingStates.includes(o.state)) {
                                                cleanReloadInterval()
                                            }
                                            setOrder(o)
                                        })
                                    }, 500)
                                })
                            }}>
                                <span className={"btn__text-gradient"}>Conferma il tuo ordine</span>
                            </button>
                        </div>
                    </div>
                    <div className={"row justify-content-center py-2 "}>
                        <div className={"row justify-content-center py-2 "}>
                            <button className="btn btn--outlined col-auto" onClick={() => {
                                api.CancelPartialOrder(id ?? "").then(o => {
                                    setOrder(o)
                                    reloadInterval.current = setInterval(() => {
                                        api.GetOrder(order.uuid).then(o => {
                                            if (!pollingStates.includes(o.state)) {
                                                cleanReloadInterval()
                                            }
                                            setOrder(o)
                                        })
                                    }, 500)
                                })
                            }}>
                                <span className={"btn__text-gradient"}>Cancella il tuo ordine</span>
                            </button>
                        </div>
                    </div>
                    <div className="row justify-content-center pb-3 mx-auto font-weight-light text--heading-color"
                         style={{maxWidth: "580px"}}>
                        Se non selezioni nessuna delle precedenti alternative entro 15 minuti il tuo ordine verrà
                        automaticamente cancellato.
                    </div>
                </div>
                <div className={"row mt-5"}></div>
                <div className={"mt-5 column text-center"}>
                    <h2 className={"mb-4"}>
                        Frequently asked <span className="font-weight-bold">questions</span>
                    </h2>
                    <div className={"mt-2 md-5"}>
                        <Accordion accordionId={data.accordionId} sections={reorderedFAQ} />
                    </div>
                </div>
                </>
            )
            break
        case PreorderState.Pending:
            content = (
                <>
                    <h1 className={"information__title text--primary-color"}><strong>Stiamo elaborando</strong> il
                        pagamento...</h1>
                </>
            )
            break
        case PreorderState.PaymentFailed:
            content = (
                <>
                    <div className={"row col-md-8 col-12 justify-content-start mx-md-auto"}>
                        <div className={"col-md-6 text-start"}>
                            <h1 className={"information__title text--primary-color"}>Checkout <strong>fallito :(</strong></h1>
                        </div>
                        <div className="col-12 px-4 mt-3">
                            <div className={"row justify-content-center pb-3 text--heading-color"}>
                                Il flusso di acquisto non è stato terminato, non ti è stato addebitato alcun importo.
                            </div>
                        </div>
                        <div className={"col-12 px-3 justify-content-start mt-5"}>
                            <button className="btn btn--gradient col-auto" onClick={() => {
                                navigate("/Partite")
                            }}>
                                <span>continua gli acquisti</span>
                            </button>
                        </div>
                    </div>
                    <div className={"row mt-5 justify-content-start"}></div>
                    <div className={"mt-5 column text-center"}>
                        <h2 className={"mb-4"}>
                            Frequently asked <span className="font-weight-bold">questions</span>
                        </h2>
                        <div className={"mt-2 md-5"}>
                            <Accordion accordionId={data.accordionId} sections={reorderedFAQ} />
                        </div>
                    </div>
                </>
            )
            break
        default:
            content = <></>
    }

    return (
        <Layout>
            <div className="checkout" style={{minHeight: "100vh"}}>
                <div className="container">
                    <div className="row justify-content-center align-items-start" style={{minHeight: "70vh"}}>
                        <div className="col-12 col-md-10 col-lg-9 text-center my-5">
                            {content}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default CheckoutCompleted
