import {ReactElement, useEffect, useState} from "react";
import Ticketorder from "@/models/Ticketorder";
import {useStore} from "@/hoc/Store/context";
import {Modal} from "react-bootstrap";
import { useMedia } from "react-media";
import AddCardModal from "@/components/AddCardModal/AddCardModal";
import {useApi} from "@/hoc/Api/context";

interface MemberCardsCarouselProps {
    onSelected: (uuid: string) => void;
    currentTicket: Ticketorder;
}

const MemberCardsCarousel = (props: MemberCardsCarouselProps): ReactElement => {
    const store = useStore()
    const api = useApi()

    const [filter, setFilter] = useState<string>("");

    const [margin, setMargin] = useState<number>(0)
    const [indexSlide, setIndexSlide] = useState<number>(0)
    const [showAddCardModal, setShowAddCardModal] = useState<boolean>(false)
    const [maxIndex, setMaxIndex] = useState<number>(0)

    const GLOBAL_MEDIA_QUERIES = {
        xs: "(max-width: 767px)",
        sm: "(max-width: 991px)",
        md: "(max-width: 1199px)",
        lg: "(max-width: 1399px)",
    };

    const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

    useEffect(() => {
        const index = matches.xs ? 1 : matches.sm ? 2 : matches.md ? 3 : matches.lg ? 4 : 5;
        const n_cards = store.getAvailableMembershipCards(props.currentTicket.eventUuid, props.currentTicket.mainIssuerUuid).length;

        setMaxIndex((n_cards - index) > 0 ? (n_cards - index) : 0)
    }, [matches])

    useEffect(() => {
        setMargin(indexSlide * -215)
    }, [indexSlide])

    const slide = (next: boolean) => {
        next ? setIndexSlide(indexSlide + 1) : setIndexSlide(indexSlide - 1)
    }

    const cardList = store.getAvailableMembershipCards(props.currentTicket.eventUuid, props.currentTicket.mainIssuerUuid).filter(m => {
        return m.valid &&
            (
                filter === "" ||
                m.fullName.toLowerCase().includes(filter.toLowerCase()) ||
                m.number.toLowerCase().includes(filter.toLowerCase())
            )
    }).map((m, index) => {
        return (
            <div className="member-card" key={index} onClick={() => {props.onSelected(m.uuid)}} data-dismiss="modal" aria-label="Close">
                <span className="member-card__colors" style={{background: "linear-gradient(105.83deg, " + m.color1 + " 0%, " + m.color2 + " 100%)"}}/>
                <div className="member-card__personal-info">
                    <p className="member-card__personal-info__owner mb-0">{m.fullName}</p>
                    <p className="label-2 mb-0 member-card__personal-info__team">{m.eventIssuer}</p>
                </div>
                <div className="member-card__general-info">
                    <p className="member-card__general-info__number label-2 mb-0">{m.number}</p>
                    <p className="member-card__general-info__associate label-2 mb-0">{"Associa"}</p>
                </div>
            </div>
        )
    })

    return (
        <div className="member-cards-carousel">
            <div className="input-group member-cards-carousel__search-box__container">

                {/* SEARCH */}
                <div className="input-group">
                    <input id="cardFilter" value={filter}
                           autoComplete="on"
                           type={"text"}
                           placeholder={"Cerca la tua tessera"}
                           onChange={(ev) => {
                               setFilter(ev.target.value)
                           }}
                           className="form-control col-lg-8 col-12 text--heading-color"
                    />
                    {/* BUTTON CLEAR SEARCH */}
                    <span className="emptySearchButton icon icon--no-margin icon--clear icon--clear--small icon--red"
                          onClick={() => {
                              setFilter("")
                          }}
                    ></span>
                </div>

            </div>

            <div style={{transform: `translateX(${margin}px)`, transition: "transform 300ms ease-in-out"}}
                 className="member-cards-carousel__container">
                <button onClick={() => {setShowAddCardModal(true)}}
                    className="member-cards-carousel__add_card-btn btn btn--outlined">
                    <span className="btn__text-gradient">{"Aggiungi tessera"}</span>
                </button>
                {cardList}
            </div>
            <div className={`member-cards-carousel__prev-btn d-flex align-items-center ${indexSlide === 0 ? "d-none" : ""}`}
                 onClick={() => {slide(false)}}
            >
                <i className="icon icon--arrow icon--arrow--right"></i>
            </div>
            <div className={`member-cards-carousel__next-btn d-flex align-items-center ${indexSlide === maxIndex ? "d-none" : ""}`}
                 onClick={() => {slide(true)}}
            >
                <i className="icon icon--arrow icon--arrow--right" />
            </div>
            {
                showAddCardModal ?

                    <Modal show={showAddCardModal}
                           onHide={() => {
                               setShowAddCardModal(false)
                           }}
                           contentClassName={"ticket-cards__modal__content"}
                           dialogClassName={"ticket-cards__modal"}
                    >
                        <AddCardModal onClose={() => {
                            setShowAddCardModal(false)
                        }} onComplete={() => {
                            setShowAddCardModal(false)
                            api.GetMembershipCards().then((cards) => {
                                store.setMembershipCard(cards)
                            })
                        }}/>
                    </Modal>
                    :
                    null
            }
        </div>
    )
}

export default MemberCardsCarousel;
