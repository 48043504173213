import {ReactElement} from "react";
import {Flipped} from "react-flip-toolkit";
import Ticket from "@/models/Ticket";
import {useApi} from "@/hoc/Api/context";
import ReductionCode from "@/models/ReductionCode";
import {useStore} from "@/hoc/Store/context";
import {toast} from "react-toastify";
import {useLocation, useNavigate} from "react-router-dom";
import {ErrorCodes} from "@/models/ErrorCodes";


interface TicketProps {
    ticket: Ticket;
    reductionCodes: ReductionCode[];
    onSelect: () => void;
}

const PDPTicket = (props: TicketProps): ReactElement => {
    const api = useApi()
    const store = useStore()
    const navigate = useNavigate()
    const location = useLocation()
    return (
        <Flipped key={`ticket-${props.ticket.uuid}`} flipId={`ticket-${props.ticket.uuid}`}>
            <div className={"row justify-content-between d-flex align-items-center py-3 ticket-container user-select-none"}>
                <div className={"col-12 col-md-4 col-lg-6"}>
                    <div className={"row d-flex justify-content-between align-items-center"}>
                        <div className={"col-md-12 col-lg-auto pb-sm-2 align-items-center"}>
                            <p className="ticket__gradientLabel text--main-accent font-weight-medium mb-1">
                                {props.reductionCodes.find((reductionCode: ReductionCode) => reductionCode.uuid === props.ticket.reductionCodeUuid)?.name}
                            </p>
                            <h2 className="ticket__title m-0 font-weight-bold ">{`${props.ticket.section} n° ${props.ticket.subSection}`}</h2>
                        </div>
                        <div className={"col-md-12 col-lg-auto"}>
                            <p className="ticket__label m-0 label py-1 py-lg-2">{`Fila: ${props.ticket.row}, Posto: ${props.ticket.seat}`}</p>
                        </div>
                    </div>
                </div>
                <div className={"col-12 col-md-6 col-lg-6 py-sm-2 py-3"}>
                    <div className={"row d-flex justify-content-between justify-content-lg-evenly align-items-center"}>
                        <div className={"col-auto pe-0"}>
                            <p className="ticket__gradientLabel--price text--primary-color font-weight-medium">
                                {`${(props.ticket.price / 100.0).toLocaleString('it-IT', {minimumFractionDigits: 2})} €*`}
                            </p>
                            {
                                props.ticket.priceOfficial ?
                                    <p className="cart-ticket__contents__edit__prices__box-office m-0 label-2">
                                        {`€ ${(props.ticket.priceOfficial / 100.0).toLocaleString('it-IT', {minimumFractionDigits: 2})}**`}
                                    </p>
                                    :
                                    null
                            }
                        </div>
                        <div className={"col-auto ps-0"}>
                            <button className="btn btn--outlined btn--outlined_no-p ticket__shop__button"
                                    onClick={() => {
                                        api.IsLogged().then(isLogged=>{
                                            if(isLogged){
                                                api.AddTicketToPreorder(props.ticket.uuid).then(r => {
                                                    api.GetPreorder().then(p => {
                                                        store.setPreorder(p)
                                                    })
                                                    props.onSelect()
                                                }).catch(() => {
                                                    toast.error("Spiacenti, si è verificato un errore")
                                                })
                                            }else{
                                                navigate(`/Auth?from=${location.pathname}&errorCode=${ErrorCodes.LOGIN_NEEDED}`)
                                            }
                                        })
                                    }}
                            >
                                <span className="btn__text-gradient">{"Aggiungi al carrello"}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Flipped>
    )
}

export default PDPTicket
