import {ReactElement, useEffect} from "react";
import {useApi} from "@/hoc/Api/context";
import Loader from "@/components/Loader";
import SellTable from "@/components/SellTable";
import {SellTicketType} from "@/components/SellTicket";
import {useStore} from "@/hoc/Store/context";
import {useSeo} from "@/hoc/Seo/context";
import {SeoPage} from "@/hoc/Seo/provider";


const Tickets = (props: any): ReactElement => {
    const seo = useSeo()
    const store = useStore()
    const api = useApi()
    const tickets = store.userTickets()
    useEffect(() => {
        seo.setSeo(SeoPage.profiloBiglietti)
        api.GetUserTickets().then(t => {
            store.setUserTickets(t)
        })
    }, [])
    if (tickets === undefined) {
        return <Loader/>
    }
    return (
        <div className="sell container">
            <SellTable title={"Biglietti acquistati"}
                       id="Acquired"
                       match={"Match"}
                       card={"Card"}
                       emptySubtitle={"Non ci sono biglietti acquistati"}
                       noFilter={false}
                       type={SellTicketType.BOUGHT}
                       noHeader={true}
                       tickets={tickets.bought}
            />
            {/* DISPONIBILI ALLA VENDITA */}
            <SellTable title={"Biglietti disponibili alla vendita"}
                       id="Saleable"
                       match={"Match"}
                       card={"Card"}
                       emptySubtitle={"Non ci sono biglietti disponibili alla vendita"}
                       noFilter={false}
                       type={SellTicketType.SELLABLE}
                       noHeader={true}
                       tickets={tickets.sellable}
            />
            {/* IN VENDITA */}
            <SellTable title={"Biglietti in vendita"}
                       id="OnSell"
                       match={"Match"}
                       type={SellTicketType.SELLING}
                       card={"Card"}
                       emptySubtitle={"Non ci sono biglietti in vendita"}
                       noFilter={false}
                       noHeader={true}
                       tickets={tickets.selling}
            />
            <SellTable title={"Biglietti invenduti"}
                       id="Unsold"
                       match={"Match"}
                       card={"Card"}
                       type={SellTicketType.UNSOLD}
                       emptySubtitle={"Non ci sono biglietti in vendita"}
                       noFilter={false}
                       noHeader={true}
                       tickets={tickets.unsold}
            />
        </div>
    )
}

export default Tickets
