import React, {ReactElement, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useApi} from "@/hoc/Api/context";
// @ts-ignore
import FrontDocument from "@/img/load-front-document.svg"
// @ts-ignore
import RearDocument from "@/img/load-back-document.svg"
import PayoutProfile, {PayoutProfileStatus} from "@/models/PayoutProfile";
import Loader from "@/components/Loader";
import {toast} from "react-toastify";

interface UploadDocumentsProps{
    onClose: () => void;
    onBack?: () => void;
    onForward?: () => void;
    profile?: PayoutProfile;
    additionalDocument: boolean;
    updateTitle: (title: string) => void;

}

interface FileUpload{
    front: FileList | String;
    rear: FileList | String;
}

const UploadDocuments = (props: UploadDocumentsProps): ReactElement => {
    const {formState, register, handleSubmit, setValue, getValues, trigger} = useForm<FileUpload>({mode:"onChange"})
    const api = useApi()

    const [docFront, setDocFront] = useState<string>(FrontDocument)
    const [docRear, setDocRear] = useState<string>(RearDocument)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        props.profile?.status === PayoutProfileStatus.KYCNeeded ?
            props.updateTitle("Dati di payout mancanti o errati - Documento") :
            props.profile?.status === PayoutProfileStatus.Complete ?
                props.updateTitle("Modifica dati di payout - Documento") :
                props.profile?.status === PayoutProfileStatus.KYCAdditionalDocumentNeeded ?
                    props.updateTitle("Dati di payout - Documento aggiuntivo") :
                    props.updateTitle("Dati di payout - Documento")
    }, [])

    const onSubmit = (data: FileUpload) => {
        let formData = new FormData()
        setLoading(true)
        formData.append("front", data.front[0])
        formData.append("rear", data.rear[0])
        if (typeof data.front !== "string" && typeof data.rear !== "string") {
            api.UploadKYCDocs(formData, props.additionalDocument).then(r => {
                setLoading(false)
                if (props.additionalDocument) {
                    props.onClose()
                }
                props.onForward ? props.onForward() : props.onClose()
            }).catch(e => {
                if (e.response.status === 413) {
                    toast.error("L'immagine è troppo grande")
                    props.onClose()
                } else {
                    toast.error("Spiacenti, si è verificato un errore")
                    props.onClose()
                }
            })
        } else {
            setLoading(false)
            props.onForward ? props.onForward() : props.onClose()
        }
    }

    useEffect(() => {
        register("front", {required: true})
        register("rear", {required: true})
        if (
            props.profile?.documentRearUuid &&
            props.profile?.documentFrontUuid &&
            props.profile?.status !== PayoutProfileStatus.KYCAdditionalDocumentNeeded &&
            props.profile?.status !== PayoutProfileStatus.KYCNeeded
        ) {
            setLoading(true)
            api.GetBase64Media(props.profile.documentFrontUuid).then(r =>
                {
                    setDocFront(r)
                    setValue("front", r, {shouldValidate: true})
                    setLoading(false)
                }
            )
            setLoading(true)
            api.GetBase64Media(props.profile.documentRearUuid).then(r =>
                {
                    setDocRear(r)
                    setValue("rear", r, {shouldValidate: true})
                    setLoading(false)
                }
            )
            setLoading(false)
        }
    }, [])

    if (loading) {
        return <div className={"py-5 py-4 my-5 my-4"}><Loader /></div>
    }

    return(
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={"row pt-2 pb-5 mb-4 justify-content-center"}>
                {
                    (props.profile?.status === PayoutProfileStatus.KYCNeeded || props.profile?.status === PayoutProfileStatus.KYCAdditionalDocumentNeeded) ?
                        <span className="font-size-medium pb-4 pt-1">
                            Il documento inserito non è valido, inserire nuove immagini Fronte-Retro di un documento d'identità valido in formato .jpg o .png.
                        </span>
                        :
                        <span className="font-size-medium pb-4 pt-1">
                            Carica sia il fronte che il retro di un documento d’identità valido in formato .jpg o .png.
                        </span>
                }

                <div className={"col-10 col-md-6 mb-4 mb-md-0 cursor-pointer"}
                     onClick={() => {document.getElementById("doc-front")?.click()}}
                >
                    <input accept="image/jpeg, image/png" type="file" id="doc-front"
                           style={{display: 'none'}}
                           onChange={(ev) => {
                               if (ev.target === null || ev.target.files === null) {return;}
                               setValue("front", ev.target?.files)
                               setDocFront(URL.createObjectURL(ev.target.files[0]))
                               if (typeof getValues("rear") === "string") {
                                   setDocRear(RearDocument)
                                   setValue("rear", "", {shouldValidate: true})
                                   trigger("rear")
                               }
                               trigger("front")
                           }}
                    />
                    <img id="documenti-front" alt="Load Front document"
                         className={docFront !== FrontDocument ? "doc-border doc-border-no-drag" : "doc-border-no-drag"}
                         src={docFront}
                         width={"100%"}
                         height={"100%"}
                    />
                </div>
                <div className={"col-10 col-md-6 cursor-pointer"}
                     onClick={() => {document.getElementById("doc-rear")?.click()}}
                >
                    <input accept="image/jpeg, image/png" type="file" id="doc-rear"
                           style={{display: 'none'}}
                           onChange={(ev) => {
                               if (ev.target === null || ev.target.files === null) {return;}
                               setValue("rear", ev.target?.files)
                               setDocRear(URL.createObjectURL(ev.target.files[0]))
                               if (typeof getValues("front") === "string") {
                                   setDocFront(FrontDocument)
                                   setValue("front", "", {shouldValidate: true})
                                   trigger("front")
                               }
                               trigger("rear")
                           }}
                    />
                    <img id="documenti-front" alt="Load Rear document"
                         className={docRear !== RearDocument ? "doc-border doc-border-no-drag" : "doc-border-no-drag"}
                         src={docRear}
                         width={"100%"}
                         height={"100%"}
                    />
                </div>
            </div>
            <div className={"row justify-content-center"}>
                {
                    !props.additionalDocument ?
                        <div className={"col-auto d-flex justify-content-center"}>
                            <button id="signin-button" className="btn btn--outlined px-4 px-md-5 py-0"
                                    onClick={() => { props.onBack ? props.onBack() : props.onClose() }}
                                    style={{lineHeight: "12px", height: "35px"}}
                            >
                                <span className="btn__text-gradient">INDIETRO</span>
                            </button>
                        </div>
                        : null
                }
                <div className={"col-auto d-flex justify-content-center"}>
                    <button type="submit"
                            disabled={!formState.isValid}
                            className="btn btn--gradient px-4 px-md-5"
                            style={{lineHeight: "12px", height: "35px"}}
                    >
                        CONTINUA
                    </button>
                </div>
            </div>
        </form>
    )
}


export default UploadDocuments
