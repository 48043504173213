export enum PinType {
    DATE = 'date',
    PIN = 'code',
}

export default interface EventIssuer {
    uuid: string;
    name: string;
    color1: string;
    color2: string;
    pinType: PinType;
    cardNumberValidator: string;
    cardNumberLabel: string;
    pinValidator: string;
    pinLabel: string;
    cardLabel: string;
    logo: string;
}
