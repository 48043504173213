import React, {ReactElement} from 'react';
import {useNavigate} from "react-router-dom";
import Moment from "react-moment";

interface SoldOutProps {
    page: string;
    mainIssuer?: string;
    secondaryIssuer?: string;
    eventLocation?: string;
    date?: string;
}

const SoldOut = (props: SoldOutProps): ReactElement => {
    const navigate = useNavigate();

    switch (props.page) {
        case "plp":
            return (
                <div className={"row col-5 justify-content-start mx-auto"}>
                     <div className="col-12 text-start">
                         <h2 className="sold-out__title text--primary-color">Non ci sono partite <span className="font-weight-bold">disponibili</span> :(</h2>
                     </div>
                     <div className="col-12 px-3 mt-4">
                         <p className="sold-out__subtitle text-start text--heading-color">{"Spiacenti, al momento non ci sono partite disponibili alla rivendita!"}</p>
                         <p className="sold-out__subtitle last text-start text--heading-color">{"Riprova più tardi o nei prossimi giorni."}</p>
                     </div>
                     <div className="col-12 px-3 justify-content-start">
                         <div className='text-center d-flex '>
                             <button className='btn btn--gradient ml-auto px-sm-3' onClick={() => {navigate("/")}}>
                                 TORNA INDIETRO
                             </button>
                         </div>
                     </div>
                </div>
            )
        case "pdp":
            return (
                <div className="row justify-content-start mx-auto">
                    {
                        props.mainIssuer && props.secondaryIssuer && props.eventLocation && props.date &&
                        <div className="col-12 PDP__header">
                            <h1 className="PDP__header__title font-weight-semibold">
                                {props.mainIssuer}
                                <span className="PDP__header__title__versus text--heading-color"> vs </span>
                                {props.secondaryIssuer}
                            </h1>

                            <p className="PDP__header__subtitle text--heading-color">{props.eventLocation}</p>
                            <p className="PDP__header__subtitle">
                                <Moment className="PDP__header__subtitle__datetime text--heading-color" locale="it"
                                        format="dddd D MMMM,">
                                    {props.date}
                                </Moment>
                                {" ore "}
                                <Moment className="text--heading-color" locale="it" format="HH:mm">
                                    {props.date}
                                </Moment>
                            </p>
                        </div>
                    }
                    <div className="col-12">
                        <h2 className="shopping-cart--empty__title">Non ci sono biglietti <span className="font-weight-bold">disponibili</span> :(</h2>
                    </div>
                    <div className="col-11 col-md-9 col-lg-7 col-xl-6">
                        <p className="shopping-cart--empty__subtitle">
                            {"Spiacenti, al momento non ci sono biglietti in vendita, riprova più tardi!"}
                        </p>
                    </div>
                    <div className="col-12">
                        <button type="button" className="shopping-cart--empty__continue btn btn--gradient"
                                onClick={() => {navigate("/Partite")}}
                        >
                            {"Torna indietro"}
                        </button>
                    </div>
                </div>
            )
        default:
            return <></>
    }

}


export default SoldOut
