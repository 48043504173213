import {ReactElement, useEffect, useState} from "react";
import Stepper from "@/components/Stepper";
import EventIssuer, {PinType} from "@/models/EventIssuer";
import ReductionCode from "@/models/ReductionCode";
import AddCardForm from "@/components/AddCardModal/AddCardForm";
import {useApi} from "@/hoc/Api/context";
import Moment from "react-moment";
import {toast} from "react-toastify";

interface AddCardModalProps {
    onClose: () => void;
    onComplete: () => void;
}

export interface AddCardFormData {
    firstName: string;
    lastName: string;
    eventIssuer: EventIssuer | undefined;
    cardNumber: string;
    pin: string;
    reductionCode: ReductionCode | undefined;
}

const AddCardModal = (props: AddCardModalProps): ReactElement => {

    const api = useApi()

    const [data, setData] = useState<AddCardFormData | undefined>(undefined);
    const [step, setStep] = useState<number>(0);

    useEffect(() => {
        document.body.style.position = 'fixed';

        return () => {
            document.body.style.position = 'unset';
        }
    }, [])

    const onComplete = (): void => {
        if (data === undefined) {
            return
        }
        api.CreateMembershipCard({
            code: data.cardNumber,
            eventIssuerUuid: data.eventIssuer?.uuid || "",
            firstName: data.firstName,
            lastName: data.lastName,
            pin: data.pin,
            reductionCodeUuid: data.reductionCode?.uuid || ""
        }).then(r => {
            props.onComplete();
        }).catch((e) => {
            console.log(e)
            if (e.response.status === 400) {
                switch (e.response.data.code) {
                    case 'membershipCardAlreadyExists':
                        toast.error("La tessera è già esistente")
                        break
                    case 'malformedRequest':
                        toast.error("I dati inseriti non sono validi")
                        break
                    default:
                        toast.error("Si è verificato un errore")
                }
            } else if (e.response.status === 500) {
                toast.error("Si è verificato un errore interno al server, si prega di riprovare più tardi")
            } else {
                toast.error("Si è verificato un errore")
            }
            props.onComplete();
        })
    }

    const modalSteps: ReactElement[] = [
        (
            <AddCardForm
                initialData={data}
                onComplete={(d) => {
                    setData(d)
                    setStep(1)
                }}
            />
        ),
        (
            <>

                <div className="row pt-3 mt-4">
                    <div className="col-12 col-md-10 offset-md-1">
                        <div className={"ticket-card"}>
                            {/* HEADER - color, name, surname */}
                            <div className="ticket-card__top"
                                 style={{background: `linear-gradient(105.83deg, ${data?.eventIssuer?.color1} 0%, ${data?.eventIssuer?.color2} 100%)`}}>
                                <div className="ticket-card__top__header">
                                    <h3 className="ticket-card__top__header__label">{`${data?.firstName} ${data?.lastName}`}</h3>
                                    <i className="ticket-cards__modal__content__member-card__top__icon icon icon--edit-btn icon--edit-btn--white icon--edit-btn--small"
                                       onClick={() => {
                                           setStep(0)
                                       }}
                                    />
                                </div>
                            </div>
                            {/* BODY - infos */}
                            <div className="ticket-card__bottom">
                                <div className="ticket-card__bottom__details">
                                    <label className="ticket-card__bottom__details__title">Dettagli</label>
                                    <p className="ticket-card__bottom__details__paragraph">
                                        <strong>{data?.eventIssuer?.pinType === PinType.DATE ? "Nato il: " : `${data?.eventIssuer?.pinLabel ?? "PIN"}: `}</strong>
                                        {data?.eventIssuer?.pinType === PinType.DATE ? (
                                            <Moment format="DD/MM/YYYY" style={{letterSpacing: "0.05em"}}>
                                                {new Date(data?.pin)}
                                            </Moment>)
                                            :
                                            data?.pin
                                        }
                                    </p>
                                    <p className="ticket-card__bottom__details__paragraph">
                                        <strong>{"Tessera N°: "}</strong>{data?.cardNumber}
                                    </p>
                                    <div className="ticket-card__bottom__details__wrapper">
                                        <p className="ticket-card__bottom__details__paragraph">
                                            <strong>{"Tipo: "}</strong>{data?.reductionCode?.name}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ticket-cards__modal__buttons row mt-4 mb-3">
                    <div className="col-12 d-flex justify-content-center">
                        <button type="button"
                                className="ticket-cards__modal__content__compile-options__btn-continue w-100 btn btn--gradient mt-3"
                                onClick={() => {
                                    onComplete()
                                }}>
                            {"Avanti"}
                        </button>
                    </div>
                </div>
            </>
        )
    ]

    return (
        <div style={{minHeight: '250px'}}>
            <div className="modal-header">
                <h5 className="ml-auto mr-auto modal-title">
                    {"Aggiungi tessera"}
                </h5>

                <button type="button" className="btn close p-0" data-dismiss="modal" aria-label="Close"
                        onClick={() => {
                            props.onClose()
                        }}
                >
                    <i aria-hidden="true" className="icon icon--close icon--default-no-opacity "/>
                </button>
            </div>

            <div className="ticket-cards__modal__custom-body modal-body container">
                {
                    <Stepper
                        currentStep={step}
                        initialStep={step}
                        onChange={(val) => {
                            setStep(val)
                        }}
                        className={"ticket-cards__modal__stepper pb-3 w-75"}
                        numSteps={2}
                    />
                }
                {
                    modalSteps[step]
                }
            </div>
        </div>
    )
}

export default AddCardModal
